import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { init, instrumentAngularRouting, BrowserTracing } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  init({
    //dsn: '__DSN__',
    dsn: "https://5042c45575544177aeb8e9fa4fd80776@o1114816.ingest.sentry.io/6146277",
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          //'localhost',
          //'https://wg7jw5zjulc04o5ob825.subbiemetrics.com',
          'https://subbiemetrics.com'],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],
    //Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
  });

  enableProdMode();

  /*
  Sentry.init({
    dsn: "https://5042c45575544177aeb8e9fa4fd80776@o1114816.ingest.sentry.io/6146277",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          //"localhost",
          "https://subbiemetrics.com",
          //"https://web-test.subbiemetrics.com"
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  */
}


function bootstrap() {
  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .then(success => {
  })
  .catch(err => console.error(err));
}


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

