<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div class="wrapper d-flex">

  <app-menu-side id="menu-side"></app-menu-side>
  <div id="content">
    <app-menu-top id="menu-top"></app-menu-top>
    <div [ngClass]="isExpanded()? 'expanded' : 'not-expanded'" class="expanded">

      <div class="container-fluid pt-5">
        <div id="main-container" class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
