import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastsContainer } from './toasts.component'

@NgModule({
  imports: [
    NgbToastModule,
    CommonModule
  ],
  declarations: [
    ToastsContainer],
  exports: [
    ToastsContainer]
})
export class ToastModule {}
