import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];

  private show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  showStandard(message: string, title?: string, delay: number = 5000) {

    let toastOptions = {
      classname: 'toast-info',
      delay: delay,
      autohide: true,
      animation: true
    };

    //Show header if provided
    if (title != null) {
      toastOptions['header'] = title
    }

    this.show(message, toastOptions);
  }

  showSuccess(message: string, title?: string, delay: number = 5000) {

    let toastOptions = {
      classname: 'toast-success',
      delay: delay,
      autohide: true,
      animation: true
    };

    //Show header if provided
    if (title != null) {
      toastOptions['header'] = title
    }

    this.show(message, toastOptions);
  }

  showWarning(message: string, title?: string, delay: number = 5000) {

    let toastOptions = {
      classname: 'toast-warning',
      delay: delay,
      autohide: true,
      animation: true
    };

    //Show header if provided
    if (title != null) {
      toastOptions['header'] = title
    }

    this.show(message, toastOptions);
  }

  showDanger(message: string, title?: string, delay: number = 5000) {

    let toastOptions = {
      classname: 'toast-danger',
      delay: delay,
      autohide: true,
      animation: true
    };

    //Show header if provided
    if (title != null) {
      toastOptions['header'] = title
    }

    this.show(message, toastOptions);
  }

  showError(message: string, title?: string, delay: number = 5000) {

    let toastOptions = {
      classname: 'toast-danger',
      delay: delay,
      autohide: true,
      animation: true
    };

    //Show header if provided
    if (title != null) {
      toastOptions['header'] = title
    }

    this.show(message, toastOptions);
  }


  showCustomToast(customTpl: any, delay: number = 3000) {
    this.show(customTpl, {
      classname: 'toast-light',
      delay: delay,
      autohide: true
    });
  }


}
