<main id="sidebar" class="sidemenu" [className]="isExpanded() ? 'expanded' : 'not-expanded'">

  <div class="d-flex flex-column flex-shrink w-100">

    <div data-test="toggle-sidemenu" class="sidebar-header" (click)="toggleSidebar()">
      <a class="navbar-brand d-flex flex-row pt-2">
        <div><img class="brand-image ms-1" src="/assets/images/subbie.png" alt="Subbiemetrics Logo"></div>
        <span *ngIf="isExpanded()" class="brand-text">Subbie</span>
      </a>
    </div>

    <ul class="nav nav-pills flex-column pt-5 mb-auto">

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="dashboard-menu-option"
          [routerLink]="['/app/dashboard']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Dashboard'" placement="right" show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-chart-bar"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="dashboard-menu-label-expanded" class="menu-text">Dashboard</div>
        </a>
      </li>

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="customers-menu-option"
          [routerLink]="['/app/customers']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Customers'" placement="right" show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-users"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="customers-menu-label-expanded" class="menu-text">Customers</div>
        </a>
      </li>

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="subscriptions-menu-option"
          [routerLink]="['/app/subscriptions']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Subscriptions'" placement="right"
            show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-clipboard-list"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="subscriptions-menu-label-expanded" class="menu-text">Subscriptions</div>
        </a>
      </li>

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="invoices-menu-option" [routerLink]="['/app/invoices']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Invoices'" placement="right" show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-file-invoice-dollar"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="invoices-menu-label-expanded" class="menu-text">Invoices</div>
        </a>
      </li>

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="payments-menu-option" [routerLink]="['/app/payments']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Payments'" placement="right" show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-credit-card"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="payments-menu-label-expanded" class="menu-text">Payments</div>
        </a>
      </li>

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="products-menu-option" [routerLink]="['/app/products']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Products'" placement="right" show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-box"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="products-menu-label-expanded" class="menu-text">Products</div>
        </a>
      </li>

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="events-menu-option" [routerLink]="['/app/events']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Events'" placement="right" show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-calendar-alt"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="events-expanded" class="menu-text">Events</div>
        </a>
      </li>

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="reports-menu-option" [routerLink]="['/app/reports']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Reports'" placement="right" show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-file-chart-line"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="reports-menu-label-expanded" class="menu-text">Reports</div>
        </a>
      </li>

      <!--

      <li *ngIf="userRole == 'Admin'" routerLinkActive="active">
       <a class="nav-item" routerLinkActive="active" [routerLink]="['/app/setup']">
          <div class="menu-icon text-center"><i class="fal fa-cog"></i></div>
          <div class="menu-text">Setup</div>
        </a>
      </li>
    </div>
    -->

      <li routerLinkActive="active">
        <a class="nav-link" routerLinkActive="active" data-test="datasources-menu-option"
          [routerLink]="['/app/datasources']">
          <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Data Sources'" placement="right"
            show-delay="200">
            <div class="menu-icon text-center"><i class="fal fa-database"></i></div>
          </div>
          <div *ngIf="isExpanded()" data-test="datasources-menu-label-expanded" class="menu-text">Data Sources</div>
        </a>
      </li>

      <!--
    <li *ngIf="userRole == 'Admin'" routerLinkActive="active">
      <a class="nav-item" routerLinkActive="active" [routerLink]="['/app/setup']">
        <div class="menu-icon text-center"><i class="fal fa-cog"></i></div>
        <div class="menu-text">Setup</div>
      </a>
    </li>
    -->

    </ul>


    <!--
    <hr>
    <div class="ps-3 pb-4">

      <a class="nav-item" routerLinkActive="active" data-test="customers-menu-option" [routerLink]="['/app/customers']">
        <div theme="light" tooltipClass="tooltip-small" [ngbTooltip]="'Setup'" placement="right" show-delay="200">
          <div class="menu-icon text-center"><i class="fal fa-cog"></i></div>
        </div>
        <div *ngIf="isExpanded()" data-test="customers-menu-label-expanded" class="menu-text">Setup</div>
      </a>

    </div>
    -->

  </div>
</main>
