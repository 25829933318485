import { Component,
  //TemplateRef
} from '@angular/core';

import { AlertService } from './alert.service';

@Component({
  selector: 'app-alerts',
  template: `
  <div *ngFor="let alert of alertService.alerts">
    <ngb-alert
      *ngIf="alert.showAlert"
      [type]="alert.type"
      [dismissable]="true"
      [animation]="true"
      (click)="alertService.remove(alert)"
      >
      {{alert.message}}
    </ngb-alert>
  </div>
  `,
  host: { 'class': 'w-80 text-center position-fixed top-0 start-50 translate-middle-x p-3', 'style': 'z-index: 1200' }
})
export class AlertsContainer {
  constructor(public alertService: AlertService) { }
}
