<footer class="mainfooter" role="contentinfo">
  <div class="footer-top">
    <div class="container-fluid"></div>
  </div>
  <div class="footer-middle">
    <div class="container py-4">
      <div class="row">
        <div class="col-md-3 col-sm-6">

          <div class="footer-pad">

            <div class="mb-2" [routerLink]="['/home']">
              <img class="footer-logo" src="/assets/images/subbiemetrics_logo_bw_alt_150x30.png"
                alt="Subbiemetrics Logo" width="150" height="30">
            </div>

          </div>
        </div>

        <div class="col-md-3 col-sm-6">

          <div class="footer-pad">
            <div class="footer-menu-heading">Product</div>
            <ul class="list-unstyled">
              <li>
                <a class="text-muted-light fw-normal mb-2" [routerLink]="['/features']">Features</a>
              </li>
              <li>
                <a class="text-muted-light fw-normal mb-2" [routerLink]="['/pricing']">Pricing</a>
              </li>
              <!--
            <li><a class="text-muted-light fw-normal mb-2" [routerLink]="['/getting-started']">Getting started</a></li>
            -->
            </ul>
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="footer-pad">
            <div class="footer-menu-heading">Company</div>
            <ul class="list-unstyled">
              <li>
                <a class="text-muted-light fw-normal mb-2" [routerLink]="['/about']">About</a>
              </li>
              <li>
                <a class="text-muted-light fw-normal mb-2" target="_blank" href="https://blog.subbiemetrics.com"
                  rel="noopener">Blog</a>
              </li>
              <li>
                <a class="text-muted-light fw-normal mb-2" [routerLink]="['/security']">Security</a>
              </li>
              <li>
                <a class="text-muted-light fw-normal mb-2" [routerLink]="['/privacy']">Privacy</a>
              </li>
              <li>
                <a class="text-muted-light fw-normal mb-2" [routerLink]="['/terms']">Terms &amp; Conditions</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <!--Column1-->
          <div class="footer-pad">
            <div class="footer-menu-heading">Help</div>
            <ul class="list-unstyled">
              <!--
            <li><a class="text-muted-light fw-normal mb-2" [routerLink]="['/documentation']">Documentation</a></li>
            -->
              <li>
                <a class="text-muted-light fw-normal mb-2" [routerLink]="['/faq']">FAQ</a>
              </li>
              <li>
                <a class="text-muted-light fw-normal mb-2" href="mailto:support@subbiemetrics.com">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="">
            © {{ year }} Subbiemetrics. All rights reserved.
          </div>
        </div>

        <div class="col-xl-3 pt-2">
          <div class="row">
            <div class="col">
              <a href="https://facebook.com/subbiemetrics" target="_blank" rel="noopener" aria-label="facebook">
                <i class="fab fa-facebook fa-2x text-muted-light" alt="facebook"></i>
              </a>
            </div>

            <div class="col">
              <a href="https://twitter.com/subbiemetrics" target="_blank" rel="noopener" aria-label="twitter">
                <i class="fab fa-x-twitter fa-2x text-muted-light" alt="twitter"></i>
              </a>
            </div>

            <div class="col">
              <a href="https://www.linkedin.com/company/subbiemetrics/" target="_blank" rel="noopener"
                aria-label="linkedin">
                <i class="fab fa-linkedin fa-2x text-muted-light" alt="linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
