/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class UserProfileService {

  public profile = {};
  userId: string;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger
  ) {

    const userData: {
      account_id: string;
      id: string;
      email: string;
      _token: string;
      _tokenExpiryDate: string
    } = JSON.parse(localStorage.getItem('userData'));

    this.userId = userData.id;

    this.getProfile();
  }

  getProfile(): any {

    const savedProfile = localStorage.getItem("profile");

    //this.logger.debug(savedProfile)

    if (savedProfile == null) {
      this.logger.log('Getting Profile from api')

      const url = environment.apiUrl + 'user-profiles/';
      this.http.get<any>(url + this.userId).subscribe((userProfile) => {

        this.logger.debug(userProfile);
        this.profile = userProfile;

        const profileString = JSON.stringify(this.profile);
        localStorage.setItem('profile', profileString);

        return this.profile;
      })

    } else {
      const profileData = JSON.parse(savedProfile);
      this.profile = profileData;
      return this.profile;
    }

  }


  getProfileSetting(setting: string): any {
    return this.profile[setting];
  }


  setProfileSetting(setting: string, value: any): Promise<boolean> {

    this.logger.debug('>>> updateing Profile');

    return new Promise((resolve) => {

      //this.profile = null;
      //localStorage.removeItem('profile');

      this.profile[setting] = value;

      const profileData = {
        [setting]: value
      }

      this.logger.debug(this.userId)
      this.logger.debug(profileData)

      this.http.put<any>(
        environment.apiUrl + 'user-profiles/' + this.userId,
        JSON.stringify(profileData)
      ).subscribe((result) => {
        this.logger.debug(result);
        //this.profile = result;
        //let profileString = JSON.stringify(this.profile);
        //localStorage.setItem('profile', profileString);
        localStorage.removeItem('profile');
        resolve(true);
      })
    })
  }
}
