import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { PipesModule } from '../../core/shared/pipes/pipes.module';

import { HomeComponent } from './home.component';

import { NgbAlertModule, NgbTooltipModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuModule } from '../../core/menu/menu.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        HomeComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbAlertModule,
        NgbTooltipModule,
        NgbProgressbarModule,
        MenuModule,
        PipesModule
    ],
    exports: [
      HomeComponent,
    ]

})
export class HomeModule { }
