import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './core/auth/auth-guard.service';

import { HomeComponent } from './components/home/home.component';

import { MainComponent } from './components/main/main.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';

const routes: Routes = [

  { path: '', component: HomeComponent },

  { path: 'login', loadChildren: () => import('./core/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'signup', loadChildren: () => import('./core/auth/signup/signup.module').then(m => m.SignupModule) },
  { path: 'reset-password', loadChildren: () => import('./core/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'new-password/:reset-token', loadChildren: () => import('./core/auth/new-password/new-password.module').then(m => m.NewPasswordModule) },
  { path: 'accept-invite/:invite-token', loadChildren: () => import('./core/auth/accept-invite/accept-invite.module').then(m => m.AcceptInviteModule) },

  { path: 'features', loadChildren: () => import('./components/home/features/features.module').then(m => m.FeaturesModule) },
  { path: 'getting-started', loadChildren: () => import('./components/home/getting-started/getting-started.module').then(m => m.GettingStartedModule) },
  { path: 'about', loadChildren: () => import('./components/home/about/about.module').then(m => m.AboutModule) },
  { path: 'pricing', loadChildren: () => import('./components/home/pricing/pricing.module').then(m => m.PricingModule) },
  { path: 'security', loadChildren: () => import('./components/home/security/security.module').then(m => m.SecurityModule) },

  { path: 'documentation', loadChildren: () => import('./components/home/documentation/documentation.module').then(m => m.DocumentationModule) },
  { path: 'faq', loadChildren: () => import('./components/home/faq/faq.module').then(m => m.FaqModule) },
  { path: 'contact', loadChildren: () => import('./components/home/contact/contact.module').then(m => m.ContactModule) },
  { path: 'privacy', loadChildren: () => import('./components/home/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'terms', loadChildren: () => import('./components/home/terms/terms.module').then(m => m.TermsModule) },

  //This needs to be ouside the app as you don't need to be logged in to see you invoice
  { path: 'invoice/view/:id', loadChildren: () => import('./components/invoices/invoice-view/invoice-view.module').then(m => m.InvoiceViewModule) },


  {
    path: 'app',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: MainComponent, children: [

      { path: 'dashboard', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },

      { path: 'customer/:id', loadChildren: () => import('./components/customers/customer/customer.module').then(m => m.CustomerModule) },
      { path: 'customers', loadChildren: () => import('./components/customers/customer-list/customer-list.module').then(m => m.CustomerListModule) },

      { path: 'product/:id', loadChildren: () => import('./components/products/product/product.module').then(m => m.ProductModule) },
      { path: 'products', loadChildren: () => import('./components/products/product-list/product-list.module').then(m => m.ProductListModule) },

      { path: 'plan/:id', loadChildren: () => import('./components/plans/plan/plan.module').then(m => m.PlanModule) },

      { path: 'subscription/:id', loadChildren: () => import('./components/subscriptions/subscription/subscription.module').then(m => m.SubscriptionModule) },
      { path: 'subscriptions', loadChildren: () => import('./components/subscriptions/subscription-list/subscription-list.module').then(m => m.SubscriptionsListModule) },

      { path: 'invoice/new', loadChildren: () => import('./components/invoices/invoice/invoice.module').then(m => m.InvoiceModule) },

      { path: 'invoice/:id', loadChildren: () => import('./components/invoices/invoice/invoice.module').then(m => m.InvoiceModule) },
      { path: 'invoices', loadChildren: () => import('./components/invoices/invoice-list/invoice-list.module').then(m => m.InvoiceListModule) },

      { path: 'payment/:id', loadChildren: () => import('./components/payments/payment/payment.module').then(m => m.PaymentModule) },
      { path: 'payments', loadChildren: () => import('./components/payments/payment-list/payment-list.module').then(m => m.PaymentListModule) },

      { path: 'taxes', loadChildren: () => import('./components/taxes/tax-list/tax-list.module').then(m => m.TaxListModule) },


      { path: 'events', loadChildren: () => import('./components/events/event-list/event-list.module').then(m => m.EventListModule) },

      { path: 'report/:id', loadChildren: () => import('./components/reports/report-viewer/report-viewer.module').then(m => m.ReportViewerModule) },
      { path: 'reports', loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule) },


      { path: 'settings/account', loadChildren: () => import('./components/accounts/account/account.module').then(m => m.AccountModule) },
      { path: 'settings/billing', loadChildren: () => import('./components/accounts/billing/billing.module').then(m => m.BillingModule) },
      { path: 'settings/notifications', loadChildren: () => import('./components/accounts/notifications/notifications.module').then(m => m.NotificationsModule) },
      { path: 'settings/integrations', loadChildren: () => import('./components/accounts/integrations/integrations.module').then(m => m.IntegrationsModule) },


      { path: 'datasources', loadChildren: () => import('./components/datasources/datasource-list/datasource-list.module').then(m => m.DatasourceListModule) },
      { path: 'datasource-select', loadChildren: () => import('./components/datasources/datasource-select/datasource-select.module').then(m => m.DatasourceSelectModule) },

      { path: 'datasources/stripe/:id', loadChildren: () => import('./components/datasources/datasource-stripe/datasources-stripe.module').then(m => m.DatasourceStripeModule) },
      { path: 'datasources/stripe', loadChildren: () => import('./components/datasources/datasource-stripe/datasources-stripe.module').then(m => m.DatasourceStripeModule) },

      { path: 'datasources/paypal/:id', loadChildren: () => import('./components/datasources/datasource-paypal/datasources-paypal.module').then(m => m.DatasourcePayPalModule) },
      { path: 'datasources/paypal', loadChildren: () => import('./components/datasources/datasource-paypal/datasources-paypal.module').then(m => m.DatasourcePayPalModule) },

      { path: 'datasources/braintree/:id', loadChildren: () => import('./components/datasources/datasource-braintree/datasources-braintree.module').then(m => m.DatasourceBraintreeModule) },
      { path: 'datasources/braintree', loadChildren: () => import('./components/datasources/datasource-braintree/datasources-braintree.module').then(m => m.DatasourceBraintreeModule) },

      { path: 'datasources/demo/:id', loadChildren: () => import('./components/datasources/datasource-demo/datasources-demo.module').then(m => m.DatasourceDemoModule) },
      { path: 'datasources/demo', loadChildren: () => import('./components/datasources/datasource-demo/datasources-demo.module').then(m => m.DatasourceDemoModule) },



      //This should redirect if unknown inside the application
      //{ path: '**', redirectTo: '/dashboard', pathMatch: 'full' }

      //{ path: 'not-found', component: PageNotFoundComponent },

      //{ path: '**', redirectTo: 'not-found' },

    ]
  },

  //This catches all routes which are not found. Must be the last item in the routes array
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    //enableTracing: true,
    onSameUrlNavigation: 'reload',
    //relativeLinkResolution: 'corrected',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledNonBlocking',
    useHash: false
})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
