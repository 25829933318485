/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { User } from './user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  jsonHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient) { }

  inviteUser(user_name: string, email: string, role: string): Observable<User> {

    const userData = new User(user_name, email, role);
    return this.http.post<User>(
      environment.apiUrl + 'users',
      JSON.stringify(userData)
    )
  }

  acceptInvite(resetToken: string, password: string) {
    //this.logger.debug('Update Password - Invite Token: ' + resetToken);
    const updatePasswordData = {
      reset_token: resetToken,
      password: password
    }
    return this.http.post(
      environment.apiUrl + 'users/accept-invite',
      JSON.stringify(updatePasswordData),
      { headers: this.jsonHeaders }
    );
  }

  getUsers(): Observable<User[]> {
      return this.http.get<User[]>(
        environment.apiUrl + 'users'
      );
  }

  getUser(id: string): Observable<User> {
    //this.logger.debug('Finding: ' + id);
    return this.http.get<User>(
      environment.apiUrl + 'users/' + id
    );
  }

  requestPasswordReset(email: string): Observable<any> {
    //this.logger.debug('Request Password Reset: ' + email);
    const requestPasswordResetData = {
      email: email
    }


    return this.http.post(
      environment.apiUrl + 'users/reset-password',
      JSON.stringify(requestPasswordResetData),
      { headers: this.jsonHeaders }
    );
  }

  updatePassword(resetToken: string, password: string) {
    //this.logger.debug('Update Password - Reset Token: ' + resetToken);
    const updatePasswordData = {
      reset_token: resetToken,
      password: password
    }
    return this.http.post(
      environment.apiUrl + 'users/update-password',
      JSON.stringify(updatePasswordData),
      { headers: this.jsonHeaders }
    );
  }

  updateUser(id: string, userData: User) {
    //this.logger.debug('Updating: ' + id);
    return this.http.put<any>(
      environment.apiUrl + 'users/' + id,
      JSON.stringify(userData));
  }

  deleteUser(id: string) {
    //this.logger.debug('Deleting: ' + id);
    return this.http.delete(environment.apiUrl + 'users/' + id);
  }

}
