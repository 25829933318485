/* eslint-disable @typescript-eslint/no-unused-vars */
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable, } from '@angular/core';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';
//import { NGXLogger } from 'ngx-logger';
//import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router,
    //private logger: NGXLogger,
    //@Inject(DOCUMENT) private document: Document,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return this.authService.user.pipe(
      take(1),
      map(user => {
        const isAuth = !!user;
        if (isAuth) {
          return true;
        }
        //this.logger.debug('>>>> Auth Gaurd Can Activate')
        if (localStorage.getItem('userData')) {
         //logged in so return true
         this.authService.autoLogin();
          return true;
        }
        return this.router.createUrlTree(['/login']);
      })
    );

    //this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    //return false;
  }


  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return this.canActivate(route, state);
  }

}
