import { Component } from '@angular/core';
//import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

//import { environment } from '../environments/environment';

import * as Cronitor from "@cronitorio/cronitor-rum-js";

import { CanonicalService } from './core/shared/services/canonical/canonical.service';
//import { UserProfileService } from './core/shared/services/user-profile/user-profile.service'


//import { Meta } from '@angular/platform-browser';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Landing Page';

   //keep refs to subscriptions to be able to unsubscribe later

   popupOpenSubscription: Subscription;
   popupCloseSubscription: Subscription;
   initializeSubscription: Subscription;
   statusChangeSubscription: Subscription;
   revokeChoiceSubscription: Subscription;
   noCookieLawSubscription: Subscription;

  constructor(
    private cookieConsentService: NgcCookieConsentService,
    private canonical: CanonicalService,
    //private meta: Meta
    ){
    }


  ngOnInit(): void {

    this.canonical.createCanonicalLink();

    /*
    //https://remotestack.io/angular-seo-tutorial-add-seo-page-title-meta-tags-and-canonical-url/

    // add new tag
    this.meta.addTag({ name: "description", content:"Meta description goes here." });

    // update meta tag
    this.meta.updateTag( { name: "description", content:"No meta found." }, "name='description'");

    // delete meta tag
    this.meta.removeTag("name='description'");
    */


    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.cookieConsentService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.cookieConsentService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.cookieConsentService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.cookieConsentService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.cookieConsentService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });


    // Load the Cronitor tracker once in your app
    //Cronitor.load("6691746a9fd6e73e80c90cc6dd9415d0");
    Cronitor.load("06013dab8ec3125c28b76621ad8f8131");

    // This is how you record page views
    // You should trigger this on router/page changes
    Cronitor.track("Pageview");

  }

  ngOnDestroy() {

    /*
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();

    */
  }


  /*
  showStandard() {
    this.toastService.show('I am a standard toast', {
      delay: 2000,
      autohide: true
    });
  }

  showSuccess() {
    this.toastService.show('I am a success toast', {
      classname: 'bg-success text-light',
      delay: 2000 ,
      autohide: true,
      headertext: 'Toast Header'
    });
  }

  showError() {
    this.toastService.show('I am a success toast', {
      classname: 'bg-danger text-light',
      delay: 2000 ,
      autohide: true,
      headertext: 'Error!!!'
    });
  }

  showCustomToast(customTpl) {
    this.toastService.show(customTpl, {
      classname: 'bg-info text-light',
      delay: 3000,
      autohide: true
    });
  }
  */

  /*
  loadStripe() {

    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }
   */

}
