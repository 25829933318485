<app-menu-header></app-menu-header>

<section class="py-5">
  <div class="container my-4 p-5">

    <div class="row p-xl-5">
      <div class="col">
        <h1 class="section-title pb-3">SaaS Metrics that Matter</h1>
        <p class="section-text">
          Advanced Metrics, Analytics and Insights for SaaS and Subscription
          Businesses
        </p>

        <p class="section-text">
          Metrics only matter if they can help you improve your performance.
          Everything else is just vanity metrics.
        </p>

        <p class="section-text">
          Subbie helps you track your MRR, LTV, Churn and CAC to help you manage
          and improve your business.
        </p>

        <p class="section-text">
          Drive growth by making data-driven decisions with confidence.
        </p>
      </div>

      <div class="col d-none d-lg-block d-flex flex-column justify-content-end">
        <div class="img-container">
          <img class="img-fluid ml-auto" src="/assets/images/signup.svg" alt="man sitting at desktop with graphs" />
        </div>
      </div>

    </div>

    <div class="row justify-content-md-center py-3">

      <div class="col-md-4 py-2 mx-auto">
        <div class="viewcard panel blue-panel rounded-lg p-4 mx-auto">
          <div class="text-center p-5">
            <i class="fa-regular fa-usd-circle fa-7x"></i>
          </div>

          <h2>Revenue</h2>

          <ul class="features mt-1 px-3">
            <li>Track your revenue in real-time from mutiple sources</li>
            <li>Quickly see where your revenue is coming from</li>
            <li>Predict future revenue trends</li>
          </ul>
        </div>
      </div>

      <div class="col-md-4 py-2 mx-auto">
        <div class="viewcard panel green-panel rounded-lg p-4 mx-auto">
          <div class="text-center p-5">
            <i class="fa-regular fa-chart-column fa-7x"></i>
          </div>

          <h2>Metrics & Analytics</h2>

          <ul class="features mt-1 px-3">
            <li>Intuitive dashboard</li>
            <li>Key metrics like MRR, Churn Rate and LTV</li>
            <li>Track your sales tax liabilities</li>
            <li>Get reports delivered to your email</li>
            <li>
              Measure your progress and gain insight into your customers
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-4 py-2 mx-auto">
        <div class="viewcard panel blue-panel rounded-lg p-4 mx-auto">
          <div class="text-center p-5">
            <i class="fa-regular fa-credit-card fa-7x"></i>
          </div>

          <h2>Payments</h2>

          <ul class="features mt-1 px-3">
            <li>Integrate with your payment processor</li>
            <li>Handle enterprise invoicing and bank payments</li>
            <li>
              Take control with a single view of all your customer payments in
              one place
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</section>

<section id="second-section" class="pb-5">
  <div class="container my-4 p-5">
    <div class="row">
      <div class="col">
        <h3>Use your preferred payment platform</h3>
        <p>
          Configure different payment methods to suit your customers, like
          credit cards for smaller subscribers and bank payments for larger
          subscribers.
        </p>
        <p>Take control with one single view of all your payment platforms.</p>
      </div>

      <div class="col-xl-4 d-none d-lg-block d-flex flex-column justify-content-end">
        <img src="../../../assets/images/woman-paying-with-credit-card-500x333.jpg" class="img-fluid rounded-lg"
          width="416" height="277" alt="paying by credit card" />
      </div>
    </div>
  </div>
</section>

<!--
<section class="pb-5">
  <div class="container my-5 p-5">
    <div class="row">

      <div class="col-xl-4 d-none d-lg-block d-flex flex-column justify-content-end">
        <img src="../../../assets/images/mrr_movement.jpg" class="img-fluid rounded-lg"
          width="416" height="277" alt="mrr movement" />
      </div>
      <div class="col px-5">
        <h3>Track your MRR is real time</h3>
        <p>
          Configure different payment methods to suit your customers, like
          credit cards for smaller subscribers and bank payments for larger
          subscribers.
        </p>
        <p>Take control with one single view of all your payment platforms.</p>
      </div>


    </div>
  </div>
</section>
-->

<section class="pt-3 pb-5">
  <div class="gray-background py-3">
    <div class="container gray-background">
      <div class="row py-5">
        <div class="col-12">
          <div class="text-center pb-4">
            <h3 class="mb-4">
              All the essential SaaS metrics to help you grow
            </h3>
          </div>

          <div class="d-flex justify-content-around">
            <div class="px-2">
              <ul>
                <li>Monthly Recurring Revenue (MRR)</li>
                <li>Annual Run Rate (ARR)</li>
                <li>
                  MRR Movement (New, Upgrades, Downgrades & Cancellations)
                </li>
                <li>Average Revenue Per Account (ARPA)</li>
                <li>Average Selling Price (ASP)</li>
                <li>Customer Churn</li>
                <li>Revenue Churn</li>
                <li>Revenue</li>
                <li>Trials & Conversions</li>
                <li>& many more</li>
              </ul>
            </div>

            <div class="px-2 pt-5 d-none d-lg-block">
              <i class="fa-regular fa-chart-user fa-7x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="fourth-section" class="pt-5">

    <div class="container">

      <div class="text-center pt-5 pb-3">
        <h3>Integrates with payment providers and platforms</h3>
      </div>

      <div class="row justify-content-center pb-5">

        <div class="col text-center mx-auto py-3">
          <a href="https://www.paypal.com/" target="_blank" rel="noopener">
            <img class="" src="../../../assets/images/logos/logo-paypal.svg" alt="paypal" width="200" />
          </a>
        </div>

        <div class="col text-center mx-auto py-3">
          <a href="https://stripe.com" target="_blank" rel="noopener">
            <img class="stripe-logo" src="../../../assets/images/logos/logo-stripe.svg" alt="stripe" width="200" />
          </a>
        </div>

        <div class="col text-center mx-auto py-3">
          <a href="https://www.braintreepayments.com/" target="_blank" rel="noopener">
            <img class="" src="../../../assets/images/logos/logo-braintree.svg" alt="braintree payments" width="200" />
          </a>
        </div>


        <!--


                   <div class="col text-center mx-auto py-3">
            <a href="https://recurly.com/" target="_blank" rel="noopener">
              <img class="" src="../../../assets/images/logos/logo-recurly.svg" alt="recurly" width="200">
            </a>
          </div>

         <div class="col text-center mx-auto py-3">
            <a href="https://appstoreconnect.apple.com" target="_blank" rel="noopener">
              <img class="" src="../../../assets/images/logos/logo-app-store-connect.svg" alt="appstore connect" width="200">
            </a>
          </div>

          <div class="col text-center mx-auto py-3">
            <a href="https://www.chargebee.com/" target="_blank" rel="noopener">
              <img class="" src="../../../assets/images/logos/logo-chargebee.svg" alt="chargebee"
                width="100" height="31">
            </a>
          </div>

          <div class="col text-center mx-auto py-3">
            <a href="https://www.shopify.com/partners" target="_blank" rel="noopener">
              <img class="logo img-fluid" src="../../../assets/images/logos/logo-shopify-partners.svg" alt="shopify"
                width="147" height="24">
            </a>
          </div>
          -->
      </div>
  </div>
</section>

<section class="d-none d-lg-block gray-background">
  <div class="container gray-background py-5">
    <div class="row py-5">
      <div class="col">
        <div class="text-center pb-4">
          <!--
<h3 class="mb-4">Choose the right plan for your business</h3>
    -->
        </div>

        <div class="d-flex justify-content-around">
          <div class="px-2">
            <a href="https://www.favourites.io/" target="_blank">
              <img class="logo" src="../../../assets/images/logos/logo-favourites.png" alt="favourites.io" width="" />
            </a>
          </div>

          <div class="px-2">
            <a href="https://weighlesswonders.ie/" target="_blank">
              <img class="logo" src="../../../assets/images/logos/logo-weighless-wonders.png" alt="weightless wonders"
                width="" />
            </a>
          </div>

          <div class="px-2">
            <img class="logo" src="../../../assets/images/logos/logo-feedbackhq.png" alt="feedbackhq" width="" />
          </div>

          <div class="px-2">
            <img class="logo" src="../../../assets/images/logos/logo-slick.png" alt="slick" width="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="fifth-section">
  <div class="container p-5">
    <div class="row py-4">
      <div class="col-3">
        <img class="img-fluid rounded-circle profile-image d-none d-md-block"
          src="../../../assets/images/karina_profile_150.jpg" alt="karina" />
      </div>

      <div class="col-9">
        <blockquote class="blockquote text-end">
          <p class="mb-0">
            Having up-to-date metrics takes a lot of the guesswork out of
            running a SaaS business
          </p>
          <footer class="blockquote-footer mt-2">
            Karina Henricks <cite title="Source Title">CEO Pinduchoo</cite>
          </footer>
        </blockquote>
      </div>
    </div>
  </div>
</section>

<div class="green-background p-5">
  <div class="container green-background">
    <div class="py-5 text-center">
      <div class="pt-3">
        Get all the latest hints and tips on SaaS Metrics and see how it affects
        your business by subscribing to our newsletter
      </div>
      <div class="pt-4">
        <a class="btn btn-lg btn-primary" target="_blank" href="https://blog.subbiemetrics.com/#/portal/signup">
          Signup for our Newsletter
        </a>
      </div>
    </div>
  </div>
</div>

<app-menu-footer></app-menu-footer>
