import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AlertService {

  alerts: any[] = [];

  private show(message: string, type: string, delay: number = 2000) {

    let alert = {
      message: message,
      type: type,
      showAlert: true
    }

    setTimeout(() => {
      alert.showAlert = false;
      this.remove(alert);
    }, delay)

    this.alerts.push(alert);
  }

  remove(alert: any) {
    this.alerts = this.alerts.filter(t => t !== alert);
  }

  showSuccess(message: string, delay: number = 2000) {
    this.show(message, 'success', delay);
  }

  showDanger(message: string, delay: number = 2000) {
    this.show(message, 'danger', delay);
  }

  showError(message: string, delay: number = 2000) {
    this.show(message, 'danger', delay);
  }

  showWarning(message: string, delay: number = 2000) {
    this.show(message, 'warning', delay);
  }

  showInfo(message: string, delay: number = 2000) {
    this.show(message, 'info', delay);
  }

}
