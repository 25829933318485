import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ShortenPipe } from './shorten.pipe';
import { PadLeftPipe } from './padleft.pipe';
import { KeysPipe } from './keys.pipe';
import { DataValuesPipe } from './values.pipe';

@NgModule({
    declarations: [
        ShortenPipe,
        PadLeftPipe,
        KeysPipe,
        DataValuesPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ShortenPipe,
        PadLeftPipe,
        KeysPipe,
        DataValuesPipe
    ]

})
export class PipesModule { }
