import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  recentProjects: [];

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private logger: NGXLogger
  ) {

      this.logger.log('Subbiemetrics | Home');


    const currentUrl = this.router.url.toLowerCase();
    if (currentUrl.indexOf('home')) {
      this.titleService.setTitle('Manage your Subscription Metrics & Analytics | Subbiemetrics');
    } else {
      this.titleService.setTitle('Subscription Metrics & Analytics | Subbiemetrics');
    }

    this.metaService.updateTag({
      name: 'description',
      content: 'Monthly Recurring Revenue (MRR), Churn, Lifetime Value (LTV), Metrics and Analytics for your Subscription Business'
    })

  }

  ngOnInit(): void {
  }

}
