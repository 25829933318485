import { BrowserModule, Meta } from '@angular/platform-browser';
import { LOCALE_ID, APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
//import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { NgxWebstorageModule } from 'ngx-webstorage';

import { environment } from '../environments/environment';

import { MainComponent } from './components/main/main.component';

import { AuthInterceptorService } from './core/auth/auth-interceptor.service';

import { PipesModule } from './core/shared/pipes/pipes.module';

import { HomeModule } from './components/home/home.module';
import { MenuModule } from './core/menu/menu.module';
import { ToastModule } from './core/toast/toast.module';
import { AlertModule } from './core/alert/alert.module';

//import { NgxPrintModule } from 'ngx-print';

import { AutofocusDirective } from './core/shared/directives/autofocus/autofocus.directive';

//import localeEnGb from '@angular/common/locales/en-GB';
//import localeEnIe from '@angular/common/locales/en-IE';

import { Router } from "@angular/router";

import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

import { UserProfileService } from './core/shared/services/user-profile/user-profile.service';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';

import {
  provideCharts,
  //withDefaultRegisterables
} from 'ng2-charts';

import {
  //BarController, BarElement
  LineController, LineElement, PointElement,
  CategoryScale, LinearScale,
  //Legend,
  //Colors,
  Filler
} from 'chart.js';


//const logLevel = environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.TRACE;
const logLevel = NgxLoggerLevel.ERROR;

//$primary: #338000; //'#f1d600'

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#000000",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#338000",
      text: "#ffffff",
      border: "transparent"
    }
  },
  type: "info",
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a> for details.

    </span>
    `,
  },
  content: {
    message: 'We use cookies to improve your experience and for marketing. Read our ',

    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: 'https://subbiemetrics.com/privacy#cookies',

    dismiss: "Accept all",
  }
};

@NgModule({
  declarations: [
    AppComponent,

    MainComponent,
    AutofocusDirective,

  ],

  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,

    AppRoutingModule,

    //HttpClientModule is only needed if you want to log on server or if you want to inspect sourcemaps
    HttpClientModule,

    HomeModule,
    NgcCookieConsentModule.forRoot(cookieConfig),

    //NgxGoogleAnalyticsModule.forRoot(environment.ga),
    //NgxGoogleAnalyticsRouterModule,

    //NgxWebstorageModule.forRoot({ prefix: 'subbie', separator: '.', caseSensitive: true }),
    //NgxWebstorageModule.forRoot({ prefix: 'custom', separator: '.', caseSensitive:true })
    // The forRoot method allows to configure the prefix, the separator and the caseSensitive option used by the library
    // Default values:
    // prefix: "ngx-webstorage"
    // separator: "|"
    // caseSensitive: false

    MenuModule,
    PipesModule,
    ToastModule,
    AlertModule,

    LoggerModule.forRoot({
      level: logLevel,
      //serverLoggingUrl: '/api/logs',
      //serverLogLevel: NgxLoggerLevel.ERROR
      serverLogLevel: NgxLoggerLevel.OFF,
      disableConsoleLogging: false
    }),

  ],
  exports: [
    PipesModule
  ],
  providers: [
    Meta,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    //{ provide: APP_BASE_HREF, useValue: '/web'},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: "en-GB" }, //your locale

    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true,
        //showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        //
      },
      deps: [TraceService],
      multi: true,
    },
    UserProfileService,
    //provideCharts(withDefaultRegisterables())
    provideCharts({
      registerables: [
        //BarController, BarElement
        LineController,LineElement,PointElement,
        CategoryScale, LinearScale,
        Filler,
        //Legend
      ]
    })
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
