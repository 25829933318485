import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../components/users/user.model';
import { UserService } from '../../../components/users/user.service';
import { AuthService } from '../../auth/auth.service';
import { MenuService } from '../menu.service';
import { UserProfileService } from '../../shared/services/user-profile/user-profile.service';


@Component({
  selector: 'app-menu-side',
  templateUrl: './menu-side.component.html',
  styleUrls: ['./menu-side.component.scss']
})
export class MenuSideComponent implements OnInit {

  _expanded: boolean;

  width = 250;

  loggedInUser;
  userRole: string;
  email: string;
  user: User;

  constructor(
    private readonly menuService: MenuService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly userProfileService: UserProfileService,
    ) {
    }

  ngOnInit() {
    this.loggedInUser = this.authService.user.value;
    this.userService.getUser(this.loggedInUser.id).subscribe((user) => {
      this.userRole = user.role;
      this.email = user.email;
    })


    this.menuService.sidebarItemSelected.subscribe((value)=> {
      //this.logger.debug('menuService sidebar: ' + value);
      this._expanded = value;
    })

    this._expanded = this.userProfileService.getProfileSetting('expand_side_menu') || false;
  }


  get expanded(): boolean {
    return this._expanded;
  }

  @Input()
  set expanded(value: boolean) {
    this._expanded = value;
  }

  isExpanded() {
    return this._expanded;
  }

  toggleSidebar() {
    //this.logger.debug(">>>> sidebar")
    this._expanded = !this._expanded;
    this.userProfileService.setProfileSetting('expand_side_menu',this._expanded);
    this.menuService.setSideMenuStatus(this._expanded);
  }

  onLogout() {
    this.authService.logout();
  }

}
