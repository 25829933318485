/* eslint-disable @typescript-eslint/no-var-requires */
export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  apiUrl: 'https://api.subbiemetrics.com/v1/',
  stripePubKey: 'pk_live_nJLmXGW1jt9DdlDT7RFDZk3100C38IkLpG',
  stripeClientId: 'ca_Fye5PSTro66GiLZ3Pq5TFFXaCMRkTAJR',
  dataSourceUrl: 'https://subbiemetrics.com/app/datasources/stripe', //cannot have # in url
  ga: 'UA-140143474-1',
  pa: '70nCptvoODM',
  paDebug: false,
  plans: {
    free: 'price_1KKRSABXUfskkJrMTCZ9QtPb',
    professionalMonthly: 'price_1KKRSsBXUfskkJrMEZ1RtR57',
    professionalYearly: 'price_1KKRSsBXUfskkJrMVy2Hcpzc',
    startupMonthly: 'price_1KKRnVBXUfskkJrM4iLLZZ7O',
    startupYearly: 'price_1KKRnVBXUfskkJrMDBbeAlyj',
    businessMonthly: 'price_1KKRoPBXUfskkJrMBE1zd93J',
    businessYearly: 'price_1KKRoPBXUfskkJrMrY0HclDl'
  },
  domain: 'subbiemetrics.com'

};
