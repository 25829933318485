import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertsContainer } from './alerts.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    NgbAlertModule,
    CommonModule
  ],
  declarations: [
    AlertsContainer],
  exports: [
    AlertsContainer]
})
export class AlertModule {}

