/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Injectable,
  //  PLATFORM_ID, Inject
} from '@angular/core';
import {
  HttpClient, HttpHeaders,
  //HttpErrorResponse
} from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { AuthUser } from './auth-user.model';
import { Router } from '@angular/router';

//import { LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
//import { DOCUMENT } from '@angular/common';

interface AuthResponseData {
  account_id: string;
  id: string;
  email: string;
  token: string;
  tokenExpiryDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<AuthUser>(null);
  //private email: string;
  private tokenExpirationTimer: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private logger: NGXLogger,
    //@Inject(PLATFORM_ID) private platformId: any,
    //@Inject(DOCUMENT) private document: Document
  ) {

  }

  signupbeta(full_name: string, email: string, account_name: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    //this.email = email;
    const signupJson = JSON.stringify({
      full_name: full_name,
      email: email,
      account_name: account_name,
    });

    //May need to handle this in the service
    return this.http.post<any>(
      environment.apiUrl + 'users/signupbeta',
      signupJson,
      { headers: headers }
    )
      .pipe(
        catchError(this.handleError)
      );
  }


  signup(user_name: string, email: string, account_name: string, password: string, account_plan: string = 'free', account_period: string = 'yearly') {

    //this.logger.log('>>>>>>>>>>>>>> Auth Service - Signup <<<<<<<<<<<<<<<<<')

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    //this.email = email;
    const signupJson = JSON.stringify({
      user_name: user_name,
      email: email,
      account_name: account_name,
      password: password,
      account_plan: account_plan || 'free',
      account_period: account_period || 'yearly'
    });

    //May need to handle this in the service
    return this.http.post<AuthResponseData>(
      environment.apiUrl + 'users/signup',
      signupJson,
      { headers: headers }
    )
      .pipe(

        catchError(this.handleError),

        tap(resData => {
          //Only authenticate if it returns with a new user
          //this.logger.log('>>> Signup tap resData');
          if (resData.token) {
            this.handleAuthentication(resData.account_id, resData.id, resData.email, resData.token, resData.tokenExpiryDate);
          }

        }));
  }


  login(email: string, password: string) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    //this.email = email;
    const bodyJson = JSON.stringify({
      email: email,
      password: password
    });

    //May need to handle this in the service
    return this.http.post<AuthResponseData>(
      environment.apiUrl + 'users/login',
      bodyJson,
      { headers: headers }
    )
      .pipe(
        catchError((error: any) => {

          //this.logger.log('>>>> Handling Error')
          this.logger.log(error);

          //this.logger.debug(error.statusText);

          let errorMessage = "An unknown error occurred!";
          if (error.status == 401) {
            errorMessage = "Invalid credentials";
          }

          return throwError(() => new Error(errorMessage));
        }),

        tap(resData => {
          if (resData.token) {
            this.logger.log(resData);
            this.logger.debug('AuthService - Handling Authentication');
            this.handleAuthentication(resData.account_id, resData.id, resData.email, resData.token, new Date(resData.tokenExpiryDate));
          }
        }));
  }

  loginGoogle(id: string) {
    //http://localhost:8989/v1/auth/google/login

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const bodyJson = JSON.stringify({
      token: id
    })

    //May need to handle this in the service
    return this.http.post<AuthResponseData>(
      environment.apiUrl + 'auth/google/login',
      bodyJson,
      { headers: headers }
    )
      .pipe(
        catchError((error: any) => {

          //this.logger.log('>>>> Handling Error')
          this.logger.log(error);

          //this.logger.debug(error.statusText);

          let errorMessage = "An unknown error occurred!";
          if (error.status == 401) {
            errorMessage = "Invalid credentials";
          }

          return throwError(() => new Error(errorMessage));
        }),

        tap(resData => {

          //this.logger.debug('>>>> Getting Google Auth Response')
          //this.logger.debug(resData);

          if (resData.token) {
            this.logger.log(resData);
            this.logger.debug('AuthService - Handling Authentication');
            this.handleAuthentication(resData.account_id, resData.id, resData.email, resData.token, new Date(resData.tokenExpiryDate));
          }
        }));

  }

  autoLogin() {

    const userData: {
      account_id: string;
      id: string;
      email: string;
      _token: string;
      _tokenExpiryDate: string
    } = JSON.parse(localStorage.getItem('userData'));

    if (!userData) {
      return;
    }

    const loadedUser = new AuthUser(
      userData.account_id,
      userData.id,
      userData.email,
      userData._token,
      new Date(userData._tokenExpiryDate)
    );

    //Check that the token is still valid
    if (loadedUser.token) {
      this.user.next(loadedUser);
      const expirationDuration = new Date(userData._tokenExpiryDate).getTime() - new Date().getTime();

      this.autoLogout(expirationDuration);
      if (expirationDuration < 0) {
        this.autoLogout(expirationDuration);
      }
    }
  }

  logout() {

     //Call the logout API - http://localhost:8989/v1/users/logout
    return this.http.post(
      environment.apiUrl + 'users/logout', {}
    ).subscribe(response => {
      this.logger.debug('>>> Logged Out');

      this.user.next(null);
      this.router.navigate(['/login']);

      //Clear out the local storage
      localStorage.removeItem('userData')

      if (this.tokenExpirationTimer) {
        clearTimeout(this.tokenExpirationTimer);
      }
      this.tokenExpirationTimer = null;
    })
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      //this.logger.debug('>>> Logging Out')
      this.logout();
    }, expirationDuration)
  }

  handleAuthentication(account_id: string, id: string, email: string, token: string, tokenExpiryDate: Date) {

    //this.logger.debug('>>>>>>>> handleAuthentication')

    const user = new AuthUser(
      account_id,
      id,
      email,
      token,
      tokenExpiryDate
    )
    //this.logger.debug(user)

    //Using the subject to emit the logged in user
    this.user.next(user);
    //const expirationDuration = new Date(user.tokenExpiryDate).getTime() - new Date().getTime();
    //this.logger.debug(expirationDuration);

    //this.logger.debug('>>>> duration')
    //this.logger.debug(expirationDuration)

    localStorage.setItem('userData', JSON.stringify(user))

    //this.autoLogout(expirationDuration);
  }

  //handleError(error: HttpErrorResponse) {
  handleError(error: any) {

    //this.logger.debug('>>>> Handling Error');
    this.logger.debug(error);

    let errorMessage = "An unknown error occurred!";
    if (!error.error || !error.error.error) {
      return throwError(errorMessage);
    } else {
      //this.logger.debug(errorRes.error)
      errorMessage = error['error']['message'].toLowerCase();

      switch (errorMessage) {
        case 'email already exists':
          errorMessage = "Email is already registered";
          break;
        case 'invalid credentials':
          errorMessage = "Invalid credentials";
          break;
        case 'email does not exist':
          errorMessage = "Invalid credentials";
          break;
      }
      return throwError(errorMessage);
    }
  }

}
