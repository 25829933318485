import { Injectable, EventEmitter } from '@angular/core';
import { UserProfileService } from '../shared/services/user-profile/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  isExpanded = true;

  sidebarItemSelected = new EventEmitter<boolean>();
  headerItemSelected = new EventEmitter<string>();

  searchTextSelected = new EventEmitter<string>();

  placeholderText = 'Search...';

  constructor(
    private userProfileService: UserProfileService
  ) {
    //Check to see what the user setting is
    this.isExpanded = this.userProfileService.getProfileSetting('expand_side_menu') || false;
  }

  toggleSidebar(): void {
    this.isExpanded = !this.isExpanded;
    this.sidebarItemSelected.emit(this.isExpanded)
  }

  setSideMenuStatus(value: boolean): void {
    this.isExpanded = value;
    this.sidebarItemSelected.emit(this.isExpanded)
  }

  setSearchText(value: string): void {
    this.searchTextSelected.emit(value)
  }

  public currentSideMenuStatus() {
    //this.logger.debug('sidebar status: ' + this.isExpanded);
    return this.isExpanded;
  }
}
