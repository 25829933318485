import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../core/menu/menu.service';
import { UserProfileService } from '../../core/shared/services/user-profile/user-profile.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  active = true;
  userProfile: any;

  constructor(
    private menuService: MenuService,
    private userProfileService: UserProfileService,
    ) {
      //console.log(this.menuService.currentSideMenuStatus());
      this.userProfile = this.userProfileService.getProfile();
      //console.log(this.userProfile);
      if (!this.userProfile) {
        this.userProfile = {
          expand_side_menu: true,
          currency: 'USD'
        }
      }
      //console.log(this.menuService.currentSideMenuStatus())
      this.menuService.isExpanded = this.userProfile.expand_side_menu;
    }

  ngOnInit(): void {
    //this.logger.debug(this.menuSerivce.currentSideMenuStatus());
  }

  toggleSideBar() {
    this.active = !this.active;

  }

  isExpanded() {
    //this.logger.debug(this.menuSerivce.currentSideMenuStatus());
    return this.menuService.currentSideMenuStatus()
  }

}
