<!--
<div class="snow"></div>
-->

<div class="navmenu">
  <div class="container">
    <nav class="navbar navbar-expand">
      <a class="navbar-brand" routerLink="/">
        <div>
          <img
            id="header-logo-mobile"
            class="img-fluid d-lg-none"
            src="/assets/images/subbie.png"
            alt="Subbiemetrics Logo"
          />
        </div>
        <img
          id="header-logo"
          class="img-fluid d-none d-lg-block d-flex flex-column"
          src="/assets/images/subbiemetrics_logo_light.png"
          alt="Subbiemetrics Logo"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item pe-4">
            <a
              id="login"
              data-test="login-link"
              class="nav-link btn btn-sm"
              routerLink="/login"
              routerLinkActive="active"
              aria-label="login"
            >
              Log in
            </a>
          </li>
          <li class="nav-item">
            <a
              id="signup"
              data-test="signup-link"
              class="nav-link btn btn-primary btn-sm text-break"
              routerLink="/signup"
              routerLinkActive="active"
              aria-label="sign up"
            >
              Get Started</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
