<div class="container pt-5 d-flex flex-column justify-content-center" style="min-height: 70vh">

  <div class="container login col-md-8 col-lg-5 mb-4 pt-3">

    <div class="pb-4 d-flex">
      <img src="/assets/images/subbiemetrics_logo_bw_230.png" alt="subbiemetrics logo" width="230" class="m-auto">
    </div>

    <div class="container card rounded-lg p-5">

      <img src="/assets/images/signup.svg">

      <div class="p-5">
        <h1 class="text-center">404 Page not found</h1>
      </div>

      <div class="p-2 text-center">
        <a class="btn btn-primary" href="/">Back to Home</a>
      </div>

      <div>
        <div class="spinner-border" *ngIf="isLoading" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

    </div>
  </div>

</div>
