<nav id="menu-top" class="navbar sticky-top">
  <div class="container-fluid">
    <div class="navbar-collapse" [ngClass]="isExpanded()? 'expanded' : 'not-expanded'">
      <div class="container-fluid">
        <div class="d-flex">

          <!--
          <form class="search-form">
            <div class="d-flex flex-row">
              <input #searchText id="searchTextBox" class="searchTextBox" name="searchTextBox" #searchTextBox
                class="form-control" type="text" (keyup.enter)="search(searchText.value)"
                [placeholder]="placeholderText" aria-label="Search" autocomplete="off">

              <span class="ps-2 pt-2" (click)="search(searchText.value)">
                <i class="fas fa-search" aria-hidden="true"></i>
              </span>
            </div>
          </form>
          -->

          <ul class="navbar-nav ms-auto me-2">

            <li class="nav-item dropdown">

              <a class="nav-link dropdown-toggle pt-3" href="#" #userDropdown id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <!--<img class="rounded-circle avatar" appGravatar [email]="gravatarEmail">-->
                <img class="rounded-circle avatar">
                {{ gravatarEmail }}
              </a>

              <ul class="dropdown-menu" data-bs-popper="none" aria-labbelledby="navbarDropdown">

                <li routerLinkActive="active">
                  <a class="dropdown-item" style="cursor: pointer" data-test="account-link"
                    [routerLink]="['/app/settings/account']">
                    <div class="menu-icon text-center"><i class="fal fa-user-circle"></i></div>
                    <div class="menu-text">Account</div>
                  </a>
                </li>

                <li routerLinkActive="active">
                  <a class="dropdown-item" style="cursor: pointer" data-test="notifications-link"
                    [routerLink]="['/app/settings/notifications']">
                    <div class="menu-icon text-center"><i class="fal fa-bell-on"></i></div>
                    <div class="menu-text">Notifications</div>
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a class="dropdown-item" style="cursor: pointer" data-test="integrations-link"
                    [routerLink]="['/app/settings/integrations']">
                    <div class="menu-icon text-center"><i class="fal fa-chart-network"></i></div>
                    <div class="menu-text">Intergrations</div>
                  </a>
                </li>
                <li routerLinkActive="active">
                  <a class="dropdown-item" style="cursor: pointer" data-test="billing-link"
                    [routerLink]="['/app/settings/billing']">
                    <div class="menu-icon text-center"><i class="fal fa-credit-card"></i></div>
                    <div class="menu-text">Billing</div>
                  </a>
                </li>

                <li>
                  <div class="dropdown-divider"></div>
                </li>

                <li>
                  <a class="dropdown-item" style="cursor: pointer" data-test="logout-link" (click)="onLogout()">
                    <div class="menu-icon text-center"><i class="fal fa-sign-out"></i></div>
                    <div class="menu-text">Log out</div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
