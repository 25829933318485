import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { PipesModule } from '../../core/shared/pipes/pipes.module';

import { MenuFooterComponent } from './menu-footer/menu-footer.component';
import { MenuFooterMiniComponent } from './menu-footer-mini/menu-footer-mini.component';
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { MenuSideComponent } from './menu-side/menu-side.component';
import { MenuTopComponent } from './menu-top/menu-top.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        MenuFooterComponent,
        MenuFooterMiniComponent,
        MenuHeaderComponent,
        MenuSideComponent,
        MenuTopComponent,

    ],
    imports: [
        CommonModule,
        RouterModule,
        PipesModule,
        NgbTooltipModule,
        FormsModule

    ],
    exports: [
      MenuFooterComponent,
      MenuFooterMiniComponent,
      MenuHeaderComponent,
      MenuSideComponent,
      MenuTopComponent,
    ]

})
export class MenuModule { }
