/*
{
  "account_id": "33768ee9-d667-415a-9de4-10834029181f",
  "id": "59b6459a-5f09-4616-ab02-d3db0d49d63c",
  "email": "jsmyth@gmail.com",
  "created_at": "2019-06-11T11:36:21.880Z",
  "updated_at": "2019-06-11T11:36:21.880Z"
}
*/

export class User {
    public account_id?: string;
    public id?: string;
    public user_name?: string;
    public email: string;
    public role: string;
    public created_at?: Date;
    public updated_at?: Date;

    constructor(
        user_name: string,
        email: string,
        role: string
    ) {
        this.user_name = user_name;
        this.email = email;
        this.role = role;
    }

}