/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { take, exhaustMap } from 'rxjs/operators';
//import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    //private logger: NGXLogger
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.user.pipe(
      take(1),
      exhaustMap(user => {
        if (!user) {
          return next.handle(req);
        } else {

          //Strip the content-type header
          req = req.clone({ headers: req.headers.delete('Content-Type', 'application/json') });

          if (req.body instanceof FormData) {
            // we are sending a file here so let it figure out the contentType automatically
            const authReq = req.clone({
              headers: new HttpHeaders({
                'Authorization': 'bearer ' + user.token
              })
            });
            return next.handle(authReq);

          } else if (req.headers.get('Accept') == 'application/pdf') {
            const authReq = req.clone({
              headers: new HttpHeaders({
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf',
                'Authorization': 'bearer ' + user.token
              })
            });
            return next.handle(authReq);

          } else {

            const authReq = req.clone({
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + user.token
              })
            });
            return next.handle(authReq);
          }
        }
      }));
  }
}
