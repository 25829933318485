import { Component, OnInit } from '@angular/core';
//import { ActivatedRoute, Router } from '@angular/router';

import { MenuService } from '../menu.service';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
//import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss']
})
export class MenuTopComponent implements OnInit {

  private userSub: Subscription;
  //private headerSub: Subscription;
  private isAuthenticated = false;

  headerType = '';
  menuItemType = '';

  //gravatarEmail="leo.moore@recinal.com";
  gravatarEmail="";

  //searchText = '';
  placeholderText = 'Search...';

  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    //private readonly logger: NGXLogger
  ) {
    this.placeholderText = this.menuService.placeholderText;
  }

  ngOnInit(): void {

    this.userSub = this.authService.user.subscribe(user => {
      this.isAuthenticated = !!user;
      //this.logger.debug(!user);
      //this.logger.debug(!!user);
      if (this.isAuthenticated) {
        //this.logger.debug(user);
        this.gravatarEmail = user.email;
      }

    });

    this.menuService.sidebarItemSelected
      .subscribe(
        (headerType: string) => {
          this.headerType = headerType;
        }
      )
  }

  setHeaderItemType(value: string) {
    //this.logger.debug(value);
    this.menuService.headerItemSelected.emit(value);
  }

  search(searchText: string) {
    //this.logger.debug(this.searchText);
    this.menuService.setSearchText(searchText);
  }

  toggleUserMenu() {

  }

  OnDestroy() {
    this.userSub.unsubscribe();
  }

  onLogout() {
    this.authService.logout();
  }

  isExpanded() {
    //this.logger.debug(this.menuSerivce.currentSideMenuStatus());
    return this.menuService.currentSideMenuStatus()
  }

}
